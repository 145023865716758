<template>
  <DashboardLeftMenu></DashboardLeftMenu>
  <MessageNotification></MessageNotification>
</template>

<script>
import DashboardLeftMenu from "@/components/DashboardLeftMenu";
import MessageNotification from "@/views/MessageNotification";
export default {
  name: "RouteMessages",
  components: {
    DashboardLeftMenu,
    MessageNotification
  }
}
</script>

<style scoped>

</style>