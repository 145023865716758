<template>
  <h1>About Page</h1>
</template>

<script>
export default {
  name: "AboutPerjuangan"
}
</script>

<style scoped>

</style>