<template>
  <DashboardLeftMenu></DashboardLeftMenu>
  <PageSettlementSummary></PageSettlementSummary>
</template>

<script>
import DashboardLeftMenu from "@/components/DashboardLeftMenu";
import PageSettlementSummary from "@/views/PageSettlementSummary";
export default {
  name: "RouterSummaryReport",
  components:{DashboardLeftMenu,PageSettlementSummary}
}
</script>

<style scoped>

</style>