import axios from 'axios';
import authHeader from './auth-header';
import router from '../router'
const API_URL = process.env.VUE_APP_BASE_URL + 'dashboard/';
class DashboardService {
    async isStillLogin() {
        const resp =  await axios.post(API_URL + 'refreshToken', {}, {headers: authHeader()})
            .then(response => {
                if (response.data.code == 1) {
                    return true;
                } else {
                    return false;
                }
            })
            .catch(error => {
                console.log(error)
                return false;
            });
        if(resp==false){
            localStorage.removeItem('user');
            router.push('/login');
        }
    }
    getUserRole(){
        const userData = localStorage.getItem('user');
        if(userData){
            const objData = JSON.parse(userData);
            return objData.role.toLowerCase()
        }else{
            return 'guest';
        }
    }
    getUserRoleId(){
        const userData = localStorage.getItem('user');
        if(userData){
            const objData = JSON.parse(userData);
            return objData.data.roleId
        }else{
            return null;
        }
    }
    getFileBrowserUrl(){
        let user = JSON.parse(localStorage.getItem('user'));
        return API_URL+'filebrowser/'+ user.token+'/';
    }
    getLastTransactionInfo(data){
        return axios.post(API_URL + 'getLastTransactionInformation',data,{headers: authHeader() });
    }
    getItemInformation(data){
        return axios.post(API_URL + 'getInformation',data,{headers: authHeader() });
    }
    readItemMessage(data){
        return axios.post(API_URL + 'readMessageInfo',data,{headers: authHeader() });
    }
    getItemMessages(data){
        return axios.post(API_URL + 'getMessages',data,{headers: authHeader() });
    }
    getItemData(path) {
        return axios.post(API_URL + 'item/'+path,{},{headers: authHeader() });
    }
    getIncomingPgLogData(data){
        if(this.isStillLogin()){
            return axios.post(API_URL + 'getPgIncomingLog/',data,{headers: authHeader() });
        }
    }
    getPayoutRequestLogData(data){
        if(this.isStillLogin()){
            return axios.post(API_URL + 'getPayoutRequestLog/',data,{headers: authHeader() });
        }
    }
    getProductsPriceListData(data){
        if(this.isStillLogin()){
            return axios.post(API_URL + 'getProductsPrice/',data,{headers: authHeader() });
        }
    }
    addProductPrice(data){
        if(this.isStillLogin()){
            return axios.post(API_URL + 'addProductPrice/',data,{headers: authHeader() });
        }
    }
    getProductMappingListData(data){
        if(this.isStillLogin()){
            return axios.post(API_URL + 'getMappingList/',data,{headers: authHeader() });
        }
    }
    getClientReportLogData(data){
        if(this.isStillLogin()){
            return axios.post(API_URL + 'getClientReportLog/',data,{headers: authHeader() });
        }
    }
    getProductsListData(data){
        if(this.isStillLogin()){
            return axios.post(API_URL + 'getProductsList/',data,{headers: authHeader() });
        }
    }
    getSettlementData(data){
        if(this.isStillLogin()){
            return axios.post(API_URL + 'getSettlementData/',data,{headers: authHeader() });
        }
    }
    getPgRequestLogData(data){
        if(this.isStillLogin()){
            return axios.post(API_URL + 'getPgRequestLog/',data,{headers: authHeader() });
        }
    }
    getRequestLogData(data){
        if(this.isStillLogin()){
            return axios.post(API_URL + 'getRequestLog/',data,{headers: authHeader() });
        }
    }
    getTopupRequestData(data){
        if(this.isStillLogin()){
            return axios.post(API_URL + 'getTopupRequestData/',data,{headers: authHeader() });
        }
    }
    getTransactionData(data){
        if(this.isStillLogin()){
            return axios.post(API_URL + 'getTransactions/',data,{headers: authHeader() });
        }
    }
    getBalanceHistory(data){
        if(this.isStillLogin()){
            return axios.post(API_URL + 'getBalanceHistory/',data,{headers: authHeader() });
        }
    }
    getWebUsersData(data){
        if(this.isStillLogin()){
            return axios.post(API_URL + 'getWebUsers/',data,{headers: authHeader() });
        }
    }
    getChartDataSatu(param){
        if(this.isStillLogin()){
            return axios.post(API_URL + 'generateChartData/',param,{headers: authHeader()});
        }
    }
    getBalanceInfo(param){
        if(this.isStillLogin()){
            let data = param == null ? {} : param;
            return axios.post(API_URL + 'getUserBalanceData/',data,{headers: authHeader()});
        }
    }
    getWebWhitelistData(){
        if(this.isStillLogin()){
            let data = {};
            return axios.post(API_URL + 'loadWhitelistText/',data,{headers: authHeader() });
        }
    }
    getPartnerInfo(data){
        if(this.isStillLogin()) {
            return axios.post(API_URL + 'getPartnerInfo/', data, {headers: authHeader()});
        }
    }
    getLinkedPartner(data){
        if(this.isStillLogin()) {
            return axios.post(API_URL + 'getLinkedPartner/', data, {headers: authHeader()});
        }
    }
    getPartnerList(data){
        if(this.isStillLogin()) {
            return axios.post(API_URL + 'getPartnersList/',data,{headers: authHeader() });
        }
    }
    registerMerchant(data){
        if(this.isStillLogin()) {
            return axios.post(API_URL + 'registerMerchant/',data,{headers: authHeader() });
        }
    }
    calculatefee(data){
        if(this.isStillLogin()) {
            return axios.post(API_URL + 'calculateFee/',data,{headers: authHeader() });
        }
    }
    generateVA(data){
        if(this.isStillLogin()) {
            return axios.post(API_URL + 'merchantProcess/',data,{headers: authHeader() });
        }
    }
    getPartnersData(data){
        if(this.isStillLogin()) {
            return axios.post(API_URL + 'getPartners/',data,{headers: authHeader() });
        }
    }
    getMerchantList(data){
        if(this.isStillLogin()) {
            return axios.post(API_URL + 'getMerchantList/',data,{headers: authHeader() });
        }
    }
    getPaymentGatewayData(data){
        if(this.isStillLogin()) {
            return axios.post(API_URL + 'getPaymentGatewayData/',data,{headers: authHeader() });
        }
    }
    getStatusCodeList(){
        let data= {}
        if(this.isStillLogin()){
            return axios.post(API_URL + 'getStatusCodeList/',data,{headers: authHeader() });
        }
    }
    getProductCategory(){
        if(this.isStillLogin()) {
            let data= {
            }
            return axios.post(API_URL + 'getProductCategory/',data,{headers: authHeader() });
        }
    }
    getPaymentGatewayList(){
        if(this.isStillLogin()) {
            let data= {
            }
            return axios.post(API_URL + 'getPaymentGatewayList/',data,{headers: authHeader() });
        }
    }
    getChannelList(action){
        if(this.isStillLogin()) {
            let data= {
                type: action
            }
            return axios.post(API_URL + 'getChannelList/',data,{headers: authHeader() });
        }
    }
    getPartnerLink(){
        if(this.isStillLogin()) {
            let data= {}
            return axios.post(API_URL + 'getPartnerLink/',data,{headers: authHeader() });
        }
    }
    getPaymentListCode(){
        if(this.isStillLogin()) {
            let data= {}
            return axios.post(API_URL + 'getPaymentList/',data,{headers: authHeader() });
        }
    }
    getVaPoolingListCode(){
        if(this.isStillLogin()) {
            let data= {}
            return axios.post(API_URL + 'getVaPoolingList/',data,{headers: authHeader() });
        }
    }
    getUserBoard() {
        return axios.get(API_URL + 'user', { headers: authHeader() });
    }
    getHolidayData(){
        if(this.isStillLogin()) {
            let data= {}
            return axios.post(API_URL + 'getHolidayList', data,{ headers: authHeader() });
        }
    }
    getTopupRequest(data){
        if(this.isStillLogin()) {
            return axios.post(API_URL + 'submitTopupRequest/', data, {headers: authHeader()});
        }
    }
    getModeratorBoard() {
        return axios.get(API_URL + 'mod', { headers: authHeader() });
    }

    getAdminBoard() {
        return axios.get(API_URL + 'admin', { headers: authHeader() });
    }
    getFeeModel(data){
        if(this.isStillLogin()) {
            return axios.post(API_URL + 'getPaymentModel',data, { headers: authHeader() });
        }
    }
    changePassword(data){
        if(this.isStillLogin()) {
            return axios.post(API_URL + 'changeWebUserPassword',data, { headers: authHeader() });
        }
    }
    addProduct(data){
        if(this.isStillLogin()) {
            return axios.post(API_URL + 'addProduct',data, { headers: authHeader() });
        }
    }
    approvalTopupRequest(data){
        if(this.isStillLogin()) {
            return axios.post(API_URL + 'approveRequestTopup',data, { headers: authHeader() });
        }
    }
    postAddWebUser(data){
        if(this.isStillLogin()){
            return axios
                .post(API_URL + 'registerWebUser/', data,{ headers: authHeader() })
                .then(response => {
                    if (response.data.token) {
                        localStorage.setItem('user', JSON.stringify(response.data));
                    }
                    return response.data;
                })
                .catch(error => {
                    console.log(error)
                });
        }
    }
    postAddPaymentGateway(data){
        if(this.isStillLogin()){
            return axios
                .post(API_URL + 'registerPaymentGateway/', data,{ headers: authHeader() })
                .then(response => {
                    if (response.data.token) {
                        localStorage.setItem('user', JSON.stringify(response.data));
                    }
                    return response.data;
                })
                .catch(error => {
                    console.log(error)
                });
        }
    }
    postWhitelistData(data){
        if(this.isStillLogin()){
            return axios
                .post(API_URL + 'saveWhitelist', data, {headers: authHeader() })
                .then(response => {
                    return response.data;
                })
        }
    }
    postEditPatner(partnerData){
        if(this.isStillLogin()){
            return axios
                .post(API_URL + 'editPartner/', partnerData,{ headers: authHeader() })
                .then(response => {
                    if (response.data.token) {
                        localStorage.setItem('user', JSON.stringify(response.data));
                    }
                    return response.data;
                })
                .catch(error => {
                    console.log(error)
                });
        }
    }
    postAddPatner(partnerData){
        if(this.isStillLogin()){
            return axios
                .post(API_URL + 'registerPartner/', partnerData,{ headers: authHeader() })
                .then(response => {
                    if (response.data.token) {
                        localStorage.setItem('user', JSON.stringify(response.data));
                    }
                    return response.data;
                })
                .catch(error => {
                    console.log(error)
                });
        }
    }
    postAddBalance(partnerData){
        if(this.isStillLogin()){
            return axios
                .post(API_URL + 'addPartnerBalance/', partnerData,{ headers: authHeader() })
                .then(response => {
                    if (response.data.token) {
                        localStorage.setItem('user', JSON.stringify(response.data));
                    }
                    return response.data;
                })
                .catch(error => {
                    console.log(error)
                });
        }
    }
}

export default new DashboardService();
