<template>
  <DashboardLeftMenu></DashboardLeftMenu>
  <TablePgIncomingLog></TablePgIncomingLog>
</template>

<script>
import DashboardLeftMenu from "@/components/DashboardLeftMenu";
import TablePgIncomingLog from "@/views/TablePgIncomingLog";
export default {
  name: "RoutePgIncomingLog",
  components:{
    DashboardLeftMenu,
    TablePgIncomingLog
  }
}
</script>

<style scoped>

</style>