<template>
  <DashboardLeftMenu></DashboardLeftMenu>
  <TableBalanceHistoryWallet></TableBalanceHistoryWallet>
</template>

<script>
import DashboardLeftMenu from "@/components/DashboardLeftMenu";
import TableBalanceHistoryWallet from "@/views/TableBalanceHistoryWallet";
export default {
  name: "RoutePageBalanceWallet",
  props:{

  },
  components:{
    TableBalanceHistoryWallet,
    DashboardLeftMenu,
  }
}
</script>

<style scoped>

</style>