<template>
  <main class="main-content">
    <NavigationTop :pages=currentRouteName :subpages=currentRouteName></NavigationTop>
    <div class="container-fluid py-4">
      <AddWebUser></AddWebUser>
      <div class="row">
        <div class="col-12">
          <div class="card mb-4">
            <div class="card-header pb-0">
              <h6>Data {{currentRouteName}}</h6>
            </div>
            <div class="card-body">
              <TableFilter :f_submit="searchSubmit" :f_loading=loading :f_user_email=true :f_user_phone=true :f_user_fullname=true :f_user_status=true></TableFilter>
            </div>
            <div class="card-body">
              <EasyDataTable
                  show-index
                  buttons-pagination
                  alternating
                  v-model:server-options="serverOptions"
                  :server-items-length="numberOfPages"
                  :loading="loading"
                  :headers="headers"
                  :items="userdata"
                  :search-field="searchField"
                  :search-value="searchValue"
              >
                <template #item-is_active="{is_active}">
                  <div class="bg-success text-center" v-if="is_active==1">
                    <span class="badge badge-success">Active</span>
                  </div>
                  <div class="bg-gray-600 text-center" v-else>
                    <span class="badge badge-warning">Not Active</span>
                  </div>
                </template>
              </EasyDataTable>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import DashboardService from "@/services/dashboard.service";
import NavigationTop from "@/components/NavigationTop";
import TableFilter from "@/views/TableFilter";
import AddWebUser from "@/views/AddWebUser";

export default {
  name: "TableUsersData",
  components:{
    NavigationTop,
    TableFilter,
    AddWebUser
  },
  computed:{
    currentRouteName() {
      return this.$route.name;
    }
  },
  methods:{
    searchSubmit(dataParam){
      this.searchParam = dataParam;
      this.searchParam.trxType = 'webUsers';
      this.searchParam.serverOptions = this.serverOptions;
      this.fetchData();
    },
    fetchData(){
      this.loading = true;
      this.searchParam.trxType = 'partners';
      this.searchParam.serverOptions = this.serverOptions;
      DashboardService.getWebUsersData(this.searchParam).then(
          (response)=>{
            this.userdata = response.data.data.data;
            this.numberOfPages = response.data.data.dataCount;
            this.loading = false;
          }
      );
    }
  },
  data:()=>({
    imageLoading: require('@/assets/img/cube_loading.gif'),
    userdata: [],
    loading: false,
    numberOfPages : 0,
    datauser:undefined,
    searchField:undefined,
    searchValue:'',
    headers: [
      { text: "Email Address", value: "email_adr" , sortable: true},
      { text: "Active", value: "is_active" , sortable: true},
      { text: "Phone", value: "mobile_phone", sortable: true },
      { text: "Full Name", value: "full_name" },
      { text: "Role", value: "userRole" , sortable: true},
    ],
    searchParam:{},
    serverOptions:{
      page: 1,
      rowsPerPage: 25,
      sortBy:'',
      sortType:'',
    }
  }),
  mounted() {
    this.fetchData();
  },
  watch:{
    serverOptions:{
      handler(){
        this.searchParam.serverOptions = this.serverOptions;
        this.fetchData(this.searchParam);
      },
      deep: true
    }
  }
}
</script>

<style scoped>

</style>