<template>
  <main class="main-content">
    <NavigationTop :pages=currentRouteName :subpages=currentRouteName></NavigationTop>
    <div class="container-fluid py-4">
      <ClientPage v-if="partnerAdd" :datapartner="datapartner" :infocolapsed=false></ClientPage>
      <ClientEditPage v-if="partnerEdit" :item="itemEdit"></ClientEditPage>
      <div class="row">
        <div class="col-12">
          <div class="card mb-4">
            <div class="card-header pb-0">
              <h6>Data {{currentRouteName}}</h6>
            </div>
            <div class="card-body">
              <TableFilter :f_submit="searchSubmit" :f_loading=loading :f_date=true :f_partner_name=true :f_partner_code=true :f_partner_status="true"></TableFilter>
            </div>
            <div class="card-body">
              <button class="btn btn-primary" @click="addNewPartner">{{addPartnerText}}</button>
              <EasyDataTable
                  show-index
                  buttons-pagination
                  alternating
                  v-model:server-options="serverOptions"
                  :server-items-length="numberOfPages"
                  :loading="loading"
                  :headers="headers"
                  :items="transactions"
                  :search-field="searchField"
                  :search-value="searchValue"
              >
                <template #expand="item">
                  <div style="padding: 15px">
                    {{item.partner_code}} named {{item.partner_name}} allowed channel {{item.partner_allow_channel}}
                  </div>
                </template>
                <template #item-partner_code="item">
                  <span class="text-bold" @click="showPartner(item)"><a href="#">{{item.partner_code}}</a></span>
                </template>
                <template #item-partner_name="{partner_name}">
                  <span>{{partner_name}}</span>
                </template>
                <template #item-partner_va="{partner_va}">
                  <span class="text-bold" v-if="partner_va">{{partner_va}}</span>
                  <span v-else>Generate to enable disbursment</span>
                </template>
                <template #item-partner_status="{partner_status}">
                  <div class="bg-success text-center" v-if="partner_status==1">
                    <span class="badge badge-success">Active</span>
                  </div>
                  <div class="bg-gray-600 text-center" v-else>
                    <span class="badge badge-warning">Not Active</span>
                  </div>
                </template>
                <template #item-partner_id="item">
                  <button class="btn btn-warning mt-5 btn-sm8" @click=editPartner(item)>Edit</button>
                </template>
                <template #loading>
                  <img :src="imageLoading" style="width: 100px; height: 80px;"/>
                </template>
              </EasyDataTable>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import DashboardService from "@/services/dashboard.service";
import NavigationTop from "@/components/NavigationTop";
import TableFilter from "@/views/TableFilter";
import ClientPage from "@/views/ClientPage";
import ClientEditPage from "@/views/ClientEditPage";
export default {
  name: "TablePartners",
  components:{
    NavigationTop,
    TableFilter,
    ClientPage,
    ClientEditPage
  },
  computed:{
    currentRouteName() {
      return this.$route.name;
    }
  },
  methods:{
    searchSubmit(dataParam){
      this.searchParam = dataParam;
      this.searchParam.trxType = 'partners';
      this.searchParam.serverOptions = this.serverOptions;
      this.fetchData();
    },
    addNewPartner(){
      this.partnerEdit = false;
      this.partnerAdd = !this.partnerAdd;
    },
    showPartner(item){
      this.partnerEdit = true;
      this.datapartner = item;
      this.infocolapsed = true;
    },
    editPartner(item){
      this.partnerEdit = true;
      this.itemEdit = item;
    },
    fetchData(){
      this.loading = true;
      this.searchParam.trxType = 'partners';
      this.searchParam.serverOptions = this.serverOptions;
      DashboardService.getPartnersData(this.searchParam).then(
          (response)=>{
            this.transactions = response.data.data.data;
            this.numberOfPages = response.data.data.dataCount;
            this.loading = false;
          }
      );
    }
  },
  data:()=>({
    imageLoading: require('@/assets/img/cube_loading.gif'),
    datapartner: undefined,
    transactions: [],
    content:"",
    partnerEdit: false,
    itemEdit: undefined,
    infocolapsed: Boolean,
    addPartnerText : 'Add New Partner',
    numberOfPages : 0,
    searchField:undefined,
    searchValue:'',
    partnerAdd: false,
    loading: false,
    headers: [
      { text: "Merchant Code", value: "partner_code" , sortable: true},
      { text: "Merchant Name", value: "partner_name" , sortable: true},
      { text: "Address", value: "partner_address" },
      { text: "Telp", value: "partner_telp" },
      { text: "Status", value: "partner_status" , sortable: true},
      { text: "AllowIP", value: "partner_allow_ip" },
      { text: "Channel", value: "partner_allow_channel" },
      { text: "RegDate", value: "partner_regdate", sortable: true},
      { text: "VA", value: "partner_va", sortable: true},
      { text: "Biller Balance", value: "partner_balance_wallet", sortable: true },
      { text: "Disbursment Balance", value: "partner_balance_disbursment", sortable: true },
      { text: "Edit", value: "partner_id", sortable: true }
    ],
    searchParam:{},
    serverOptions:{
      page: 1,
      rowsPerPage: 25,
      sortBy:'',
      sortType:'',
    }
  }),
  mounted() {
    this.fetchData();
  },
  beforeUnmount() {
  },
  watch:{
    serverOptions:{
      handler(){
        this.searchParam.serverOptions = this.serverOptions;
        this.fetchData(this.searchParam);
      },
      deep: true
    },
  }
}
</script>

<style scoped>

</style>