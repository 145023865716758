<template>
  <div class="container-fluid py-4">
    <div class="row">
      <div class="col-12">
        <div class="card mb-4">
          <div class="card-header pb-0">
            <h6>Data {{currentRouteName}}</h6>
          </div>
        </div>
        <div class="row">
          <div class="card card-body blur shadow-blur mx-4 mt-n6 overflow-hidden">
            <div class="row gx-4">
              <div class="col-auto">
                <div class="avatar avatar-xl position-relative">
                  <img :src="imageIcon" alt="profile_image" class="w-100 border-radius-lg shadow-sm">
                </div>
              </div>
              <div class="col-auto my-auto">
                <div class="h-100">
                  <h5 class="mb-1">
                    {{getFullName()}}
                  </h5>
                  <p class="mb-0 font-weight-bold text-sm">
                    {{getEmailAddress()}}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card mb-3 mt-3">
          <div class="card-header pb-0 p-3">
            <div class="row mb-3" v-if="!loading">
              <div class="col-md-6">
                <VueMultiselect
                    :multiple="false"
                    v-model="partnerId"
                    :options="partnerOptions"
                    :taggable="false"
                    label="name"
                    track-by="name"
                    placeholder="Select Merchant"
                >
                </VueMultiselect>
              </div>
            </div>
          </div>
          <div class="card-body p-3">
            <div class="row mb-2">
              <div class="col-md-6">
                <div class="d-flex flex-column" v-if="partnerData">
                  <h6 class="mb-3 text-sm">Merchant name:<span class="text-uppercase">{{partnerData.partner_name}}</span></h6>
                  <span class="mb-2 text-xs">Status: <span class="text-dark font-weight-bold ms-sm-2">{{partnerData.status}}</span></span>
                  <span class="mb-2 text-xs">Partner Code: <span class="text-dark font-weight-bold ms-sm-2">{{partnerData.partner_code}}</span></span>
                  <span class="mb-2 text-xs">Secret Key: <span class="text-dark font-weight-bold ms-sm-2">{{secretKey}}</span> <i @click="showSecret" :class=eyeicon class="cursor-pointer"></i></span>
                  <span class="mb-2 text-xs">Mobile Phone: <span class="text-dark font-weight-bold ms-sm-2">{{partnerData.partner_telp}}</span></span>
                  <span class="mb-2 text-xs">Address: <span class="text-dark ms-sm-2 font-weight-bold">{{partnerData.partner_address}}</span></span>
                  <span class="mb-2 text-xs">Email Address: <span class="text-dark ms-sm-2 font-weight-bold">{{partnerData.partner_email}}</span></span>
                  <span class="text-xs" v-if="partnerData.va!='-'">VA Number: <span class="text-dark ms-sm-2 font-weight-bold">{{partnerData.va}}</span></span>
                </div>
              </div>
              <div class="col-md-6">
                <div class="card">
                  <div class="card-body p-3">
                    <div class="row">
                      <div class="col-8">
                        <div class="numbers">
                          <p class="text-sm mb-0 text-capitalize font-weight-bold">Ready To Settle</p>
                          <h5 class="font-weight-bolder mb-0" v-if="partnerData">
                            {{ partnerData.partner_ready_sattle }}
                          </h5>
                        </div>
                      </div>
                      <div class="col-4 text-end">
                        <div class="icon icon-shape bg-gradient-primary shadow text-center border-radius-md">
                          <v-avatar
                            size="36px"
                            class="mt-2"
                          >
                            <v-img
                              alt="Avatar"
                              :src="require('@/assets/img/idr-icon.png')"
                            ></v-img>
                          </v-avatar>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="partnerData">
              <!-- <v-row class="mt-2">
                <v-col>
                  <v-radio-group
                    v-model="percentageRadio"
                    inline
                  >
                    <v-radio
                      label="Amount"
                      color="indigo"
                      value="amount"
                    ></v-radio>
                    <v-radio
                      label="Percentage"
                      color="orange"
                      value="percentage"
                    ></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row> -->
              <v-row class="mt-2">
                <v-col >
                </v-col>
                <v-col >
                  <p class="text-subtitle-1">Percentage</p>
                </v-col>
                <v-col>
                  <!-- <p class="text-subtitle-1">{{percentageRadio == 'amount' ? 'Inputted Amount' : 'Gross Amount'}}</p> -->
                  <p class="text-subtitle-1">Amount</p>
                </v-col>
                <v-col >
                  <p class="text-subtitle-1">Admin Fee</p>
                </v-col>
                <v-col >
                  <p class="text-subtitle-1">Net Amount</p>
                </v-col>
              </v-row>
              <!-- <v-row v-if="isSplitPayout == 1">
                <v-col >
                  <p class="text-subtitle-1">Wallet</p>
                </v-col>
                <v-col >
                  <v-text-field label="" type="number" v-model="formattedWalletPercentage"></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field label="" v-model="formattedWalletGrossAmount" ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field label="" v-model="formattedWalletFeeAmount"></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field label="" v-model="formattedWalletNetAmount" readonly></v-text-field>
                </v-col>
              </v-row>
              <v-row v-if="isSplitPayout == 1">
                <v-col >
                  <p class="text-subtitle-1">Disbursement</p>
                </v-col>
                <v-col v-if="percentageRadio == 'percentage'">
                  <v-text-field label="" type="number" v-model="formattedDisbursementPercentage"></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field label="" v-model="formattedDisbursementGrossAmount" :readonly="percentageRadio == 'percentage'"></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field label="" v-model="formattedDisbursementFeeAmount"  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field label="" v-model="formattedDisbursementNetAmount"  ></v-text-field>
                </v-col>
              </v-row> -->
              <v-row>
                <v-col >
                  <p class="text-subtitle-1">Local Bank</p>
                </v-col>
                <v-col >
                  <v-text-field label="" type="number" v-model="formattedLocalbankPercentage"></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field label="" v-model="formattedLocalbankGrossAmount" ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field label="" v-model="formattedLocalbankFeeAmount"  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field label="" v-model="formattedLocalbankNetAmount"  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col >
                  <p class="text-subtitle-1">Crypto</p>
                </v-col>
                <v-col >
                  <v-text-field label="" type="number" v-model="formattedCryptoPercentage"></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field label="" v-model="formattedCryptoGrossAmount" ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field label="" v-model="formattedCryptoFeeAmount"  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field label="" v-model="formattedCryptoNetAmount"  ></v-text-field>
                </v-col>
              </v-row>
              <v-row v-if="addressTrc == 1">
                <v-col cols="3">
                  <p class="text-subtitle-1">Wallet Address TRC20</p>
                </v-col>
                <v-col>
                  <v-text-field label="" v-model="request.req_wallet_address_trc20" ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col >
                  <p class="text-subtitle-1">Overdraft</p>
                </v-col>
                <v-col >
                  <v-text-field label="" type="number" v-model="formattedOverdraftPercentage"></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field label="" v-model="formattedOverdraftGrossAmount" ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field label="" v-model="formattedOverdraftFeeAmount"  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field label="" v-model="formattedOverdraftNetAmount"  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col >
                  <ConfirmationDialogButton @submit="submitRequest" :modelParam="request" :splitPayout="isSplitPayout"></ConfirmationDialogButton>
                </v-col>
              </v-row>
            </div>
            
          </div>         
        </div>
        <div class="card mb-3">
          <div class="col-md-12 mb-lg-0 mb-4">
            <div class="card mt-4">
              <div class="card-header pb-0 p-3">
                <div class="row">
                  <!-- <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4" v-if="isSplitPayout == 1">
                    <div class="card">
                      <div class="card-body p-3">
                        <div class="row">
                          <div class="col-8">
                            <div class="numbers">
                              <p class="text-sm mb-0 text-capitalize font-weight-bold">Wallet</p>
                              <h5 class="font-weight-bolder mb-0" v-if="partnerData">
                                {{ partnerData.partner_balance_wallet }}
                              </h5>
                            </div>
                          </div>
                          <div class="col-4 text-end">
                            <div class="icon icon-shape bg-gradient-primary shadow text-center border-radius-md">
                              <i class="ni ni-money-coins text-lg opacity-10" aria-hidden="true"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4" v-if="isSplitPayout == 1">
                    <div class="card">
                      <div class="card-body p-3">
                        <div class="row">
                          <div class="col-8">
                            <div class="numbers">
                              <p class="text-sm mb-0 text-capitalize font-weight-bold">Disbursement</p>
                              <h5 class="font-weight-bolder mb-0" v-if="partnerData">
                                {{ partnerData.partner_balance_disbursment }}
                              </h5>
                            </div>
                          </div>
                          <div class="col-4 text-end">
                            <div class="icon icon-shape bg-gradient-primary shadow text-center border-radius-md">
                              <i class="ni ni-money-coins text-lg opacity-10" aria-hidden="true"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> -->
                  <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
                    <div class="card">
                      <div class="card-body p-3">
                        <div class="row">
                          <div class="col-8">
                            <div class="numbers">
                              <p class="text-sm mb-0 text-capitalize font-weight-bold">Crypto</p>
                              <h5 class="font-weight-bolder mb-0" v-if="partnerData">
                                {{ partnerData.partner_balance_disbursment }}
                              </h5>
                            </div>
                          </div>
                          <div class="col-4 text-end">
                            <div class="icon icon-shape bg-gradient-primary shadow text-center border-radius-md">
                              <i class="ni ni-money-coins text-lg opacity-10" aria-hidden="true"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
                    <div class="card">
                      <div class="card-body p-3">
                        <div class="row">
                          <div class="col-8">
                            <div class="numbers">
                              <p class="text-sm mb-0 text-capitalize font-weight-bold">Local Bank</p>
                              <h5 class="font-weight-bolder mb-0" v-if="partnerData">
                                {{ partnerData.partner_balance_disbursment }}
                              </h5>
                            </div>
                          </div>
                          <div class="col-4 text-end">
                            <div class="icon icon-shape bg-gradient-primary shadow text-center border-radius-md">
                              <i class="ni ni-money-coins text-lg opacity-10" aria-hidden="true"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
                    <div class="card">
                      <div class="card-body p-3">
                        <div class="row">
                          <div class="col-8">
                            <div class="numbers">
                              <p class="text-sm mb-0 text-capitalize font-weight-bold">Overdraft </p>
                              <h5 class="font-weight-bolder mb-0" v-if="partnerData">
                                {{ partnerData.partner_overdraft }}
                              </h5>
                            </div>
                          </div>
                          <div class="col-4 text-end">
                            <div class="icon icon-shape bg-gradient-primary shadow text-center border-radius-md">
                              <i class="ni ni-money-coins text-lg opacity-10" aria-hidden="true"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row alert alert-light" role="alert" v-if="submitmessage">
                  {{submitmessage}}
                </div>
                
              </div>
            </div>
          </div>
        </div>
        <div class="card mb-3">
          <TableSettlement></TableSettlement>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UserService from "@/services/user.service";
import DashboardService from "@/services/dashboard.service";
import TableSettlement from '@/views/settlement/List.vue';
import {formatNumber, getFormattedNumber} from "@/services/number-format"
import ConfirmationDialogButton from "@/components/ConfirmationDialogButton.vue"

export default {
  name: "SettlementPage",
  components:{
    TableSettlement,
    ConfirmationDialogButton
  },
  computed:{
    currentRouteName() {
      return this.$route.name;
    },
    formattedWalletPercentage: {
      get() {
        return this.percentageWallet;
      },
      set(newValue) {
        this.percentageWallet = newValue;
        this.request.req_wallet_gross_amt = newValue * this.partnerData.partner_ready_sattle_raw / 100;
        this.request.req_wallet = this.request.req_wallet_gross_amt - this.request.req_wallet_fee_amt;
      }
    },
    formattedWalletGrossAmount: {
      get() {
        return formatNumber(this.request.req_wallet_gross_amt, 0);
      },
      set(newValue) {
        this.request.req_wallet_gross_amt = getFormattedNumber(newValue);
        this.request.req_wallet = this.request.req_wallet_gross_amt - this.request.req_wallet_fee_amt;
      }
    },
    formattedWalletFeeAmount: {
      get() {
        return formatNumber(this.request.req_wallet_fee_amt, 0);
      },
      set(newValue) {
        this.request.req_wallet_fee_amt = getFormattedNumber(newValue);
        this.request.req_wallet = this.request.req_wallet_gross_amt - this.request.req_wallet_fee_amt;
      }
    },
    formattedWalletNetAmount: {
      get() {
        return formatNumber(this.request.req_wallet, 0);
      },
      set(newValue) {
        this.request.req_wallet = getFormattedNumber(newValue);
      }
    },
    formattedDisbursementPercentage: {
      get() {
        return this.percentageDisbursement;
      },
      set(newValue) {
        this.percentageDisbursement = newValue;
        this.request.req_disburesement_gross_amt = newValue * this.partnerData.partner_ready_sattle_raw / 100;
        this.request.req_disburesement = this.request.req_disburesement_gross_amt - this.request.req_disburesement_fee_amt;
      }
    },
    formattedDisbursementGrossAmount: {
      get() {
        return formatNumber(this.request.req_disburesement_gross_amt, 0);
      },
      set(newValue) {
        this.request.req_disburesement_gross_amt = getFormattedNumber(newValue);
        this.request.req_disburesement = this.request.req_disburesement_gross_amt - this.request.req_disburesement_fee_amt;
      }
    },
    formattedDisbursementFeeAmount: {
      get() {
        return formatNumber(this.request.req_disburesement_fee_amt, 0);
      },
      set(newValue) {
        this.request.req_disburesement_fee_amt = getFormattedNumber(newValue);
        this.request.req_disburesement = this.request.req_disburesement_gross_amt - this.request.req_disburesement_fee_amt;
      }
    },
    formattedDisbursementNetAmount: {
      get() {
        return formatNumber(this.request.req_disburesement, 0);
      },
      set(newValue) {
        this.request.req_disburesement = getFormattedNumber(newValue);
      }
    },
    formattedLocalbankPercentage: {
      get() {
        return this.percentageLocalbank;
      },
      set(newValue) {
        this.percentageLocalbank = newValue;
        this.request.req_local_bank_gross_amt = newValue * this.partnerData.partner_ready_sattle_raw / 100;
        this.request.req_local_bank = this.request.req_local_bank_gross_amt - this.request.req_local_bank_fee_amt;
      }
    },
    formattedLocalbankGrossAmount: {
      get() {
        return formatNumber(this.request.req_local_bank_gross_amt, 0);
      },
      set(newValue) {
        let val = getFormattedNumber(newValue);
        this.percentageLocalbank = val*100/this.partnerData.partner_ready_sattle_raw;
        this.request.req_local_bank_gross_amt = getFormattedNumber(val);
        this.request.req_local_bank = this.request.req_local_bank_gross_amt - this.request.req_local_bank_fee_amt;
      }
    },
    formattedLocalbankFeeAmount: {
      get() {
        return formatNumber(this.request.req_local_bank_fee_amt, 0);
      },
      set(newValue) {
        this.request.req_local_bank_fee_amt = getFormattedNumber(newValue);
        this.request.req_local_bank = this.request.req_local_bank_gross_amt - this.request.req_local_bank_fee_amt;
      }
    },
    formattedLocalbankNetAmount: {
      get() {
        return formatNumber(this.request.req_local_bank, 0);
      },
      set(newValue) {
        this.request.req_local_bank = getFormattedNumber(newValue);
      }
    },
    formattedCryptoPercentage: {
      get() {
        return this.percentageCrypto;
      },
      set(newValue) {
        this.percentageCrypto = newValue;
        this.request.req_crypto_gross_amt = newValue * this.partnerData.partner_ready_sattle_raw / 100;
        this.request.req_crypto = this.request.req_crypto_gross_amt - this.request.req_crypto_fee_amt;
        if (this.request.req_crypto_gross_amt > 0) {
          this.addressTrc = 1;
        } else {
          this.addressTrc = 0;
        }
        console.log('this.addressTrc', this.addressTrc);
      }
    },
    formattedCryptoGrossAmount: {
      get() {
        return formatNumber(this.request.req_crypto_gross_amt, 0);
      },
      set(newValue) {
        let val = getFormattedNumber(newValue);
        this.request.req_crypto_gross_amt = getFormattedNumber(val);
        this.percentageCrypto = val*100/this.partnerData.partner_ready_sattle_raw;
        this.request.req_crypto = this.request.req_crypto_gross_amt - this.request.req_crypto_fee_amt;
        if (this.request.req_crypto_gross_amt > 0) {
          this.addressTrc = 1;
        } else {
          this.addressTrc = 0;
        }
        console.log('this.addressTrc', this.addressTrc);
      }
    },
    formattedCryptoFeeAmount: {
      get() {
        return formatNumber(this.request.req_crypto_fee_amt, 0);
      },
      set(newValue) {
        this.request.req_crypto_fee_amt = getFormattedNumber(newValue);
        this.request.req_crypto = this.request.req_crypto_gross_amt - this.request.req_crypto_fee_amt;
      }
    },
    formattedCryptoNetAmount: {
      get() {
        return formatNumber(this.request.req_crypto, 0);
      },
      set(newValue) {
        this.request.req_crypto = getFormattedNumber(newValue);
      }
    },
    formattedOverdraftPercentage: {
      get() {
        return this.percentageOverdraft;
      },
      set(newValue) {
        this.percentageOverdraft = newValue;
        this.request.req_overdraft_gross_amt = newValue * this.partnerData.partner_ready_sattle_raw / 100;
        this.request.req_overdraft = this.request.req_overdraft_gross_amt - this.request.req_overdraft_fee_amt;
      }
    },
    formattedOverdraftGrossAmount: {
      get() {
        return formatNumber(this.request.req_overdraft_gross_amt, 0);
      },
      set(newValue) {
        let val = getFormattedNumber(newValue);
        this.request.req_overdraft_gross_amt = getFormattedNumber(val);
        this.percentageOverdraft = val*100/this.partnerData.partner_ready_sattle_raw;
        this.request.req_overdraft = this.request.req_overdraft_gross_amt - this.request.req_overdraft_fee_amt;
      }
    },
    formattedOverdraftFeeAmount: {
      get() {
        return formatNumber(this.request.req_overdraft_fee_amt, 0);
      },
      set(newValue) {
        this.request.req_overdraft_fee_amt = getFormattedNumber(newValue);
        this.request.req_overdraft = this.request.req_overdraft_gross_amt - this.request.req_overdraft_fee_amt;
      }
    },
    formattedOverdraftNetAmount: {
      get() {
        return formatNumber(this.request.req_overdraft, 0);
      },
      set(newValue) {
        this.request.req_overdraft = getFormattedNumber(newValue);
      }
    },
  },
  methods:{
    getFullName(){
      return UserService.getUserFullName();
    },
    getEmailAddress(){
      return UserService.getUserEmail();
    },
    getPartnerList(){
      this.loading = true;
      DashboardService.getLinkedPartner(this.dataJsonInfo).then(
          (response)=>{
            if(response.data.code==1){
              this.partnerOptions = response.data.data;
              let roleId = DashboardService.getUserRoleId();
              if(roleId != 0) {
                this.partnerId = response.data.data[0];
              }
              this.loading = false;
            }else{
              this.loading = false;
            }
          }
      )
    },
    getPartnerInfo(){
      this.loading = true;
      DashboardService.getPartnerInfo(this.partnerJson).then(
          (response)=>{
            if(response.data.code==1){
              this.partnerData = response.data.data.data[0];
              this.loading = false;
            }else{
              this.loading = false;
            }
          }
      )
    },
    submitRequest(){
      console.log('submit');
      this.submitloading = true;
      this.submitmessage = '';
      this.request.req_partnerid = this.partnerJson.partnerId;
      DashboardService.getTopupRequest(this.request).then(
          (response)=>{
            if(response.data.code==1){
              this.submitloading = false;
              this.submitmessage = response.data.message;
              this.$router.push("/dashboard");
            }else{
              this.submitmessage = response.data.message;
              this.submitloading = false;
            }
          }
      )
    },
    showSecret(){
      this.openSecret = !this.openSecret;
      if(this.openSecret){
        this.eyeicon = 'fa-regular fa-eye-slash';
        this.secretKey = this.partnerData.partner_secret;
      }else{
        this.eyeicon = 'fa-regular fa-eye';
        this.secretKey = '********';
      }
    },
  },
  data:()=>({
    loading: false,
    submitloading: false,
    submitmessage:'',
    secretKey: '********',
    openSecret: false,
    eyeicon: 'fa-regular fa-eye-slash',
    imageIcon: require('@/assets/img/logo-ct-dark.png'),
    merchantList:['T0001','T0002','T0003'],
    partnerOptions:[{id:1,text:'Test'},{id:2,text:'Test2'}],
    partnerId:'',
    partnerData:[],
    partnerJson:{
      partnerId:'',
      otpva:'',
    },
    percentageRadio:'amount',
    percentageWallet: null,
    percentageDisbursement: null,
    percentageLocalbank: null,
    percentageCrypto: null,
    percentageOverdraft: null,
    addressTrc: '',
    isSplitPayout: 1,
    request:{
      req_partnerid:'',
      req_wallet: 0,
      req_wallet_fee_amt: 0,
      req_wallet_gross_amt: 0,
      req_disburesement:0,
      req_disburesement_fee_amt:0,
      req_disburesement_gross_amt:0,
      req_local_bank:0,
      req_local_bank_fee_amt:0,
      req_local_bank_gross_amt:0,
      req_crypto:0,
      req_crypto_fee_amt:0,
      req_crypto_gross_amt:0,
      req_wallet_address_trc20:0,
      req_overdraft:0,
      req_overdraft_fee_amt:0,
      req_overdraft_gross_amt:0
    }
  }),
  beforeMount() {
    this.getPartnerList();
    const loggedIn = localStorage.getItem('user');
    if(loggedIn != null){
        let dataUser = JSON.parse(loggedIn);
        this.isSplitPayout = dataUser.split_payout;
    }
  },
  watch:{
    partnerId:{
      immediate: true,
      deep: true,
      handler(){
        this.partnerJson.partnerId = this.partnerId.code;
        this.getPartnerInfo(this.partnerJson);
      }
    }
  }
}
</script>

<style scoped>

</style>