<template>
  <DashboardLeftMenu></DashboardLeftMenu>
  <TableProductMapping></TableProductMapping>
</template>

<script>
import DashboardLeftMenu from "@/components/DashboardLeftMenu";
import TableProductMapping from "@/views/TableProductMapping";
export default {
  name: "RouteProductMapping",
  components:{
    DashboardLeftMenu,
    TableProductMapping
  }

}
</script>

<style scoped>

</style>