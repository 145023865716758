<template>
  <nav class="navbar navbar-main navbar-expand-lg px-0 mx-4 shadow-none border-radius-xl" id="navbarBlur" navbar-scroll="true">
    <div class="container-fluid py-1 px-3">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb bg-transparent mb-0 pb-0 pt-1 px-0 me-sm-6 me-5">
          <li class="breadcrumb-item text-sm"><a class="opacity-5 text-dark" href="javascript:;">Pages</a></li>
          <li class="breadcrumb-item text-sm text-dark active" aria-current="page">{{pages}}</li>
        </ol>
        <h6 class="font-weight-bolder mb-0">{{subpages}}</h6>
      </nav>
      <div class="collapse navbar-collapse mt-sm-0 mt-2 me-md-0 me-sm-4 justify-content-end" id="navbar">
        <ul class="navbar-nav justify-content-end rounded-pill p-2 bg-gradient-primary">
          <li class="nav-item px-3 d-lg-none d-xl-none d-flex align-items-center" style="color: #ffffff;">
            <a @click="toggle" data-bs-target="sidenav-collapse-main" data-bs-toggle="collapse" class="nav-link text-body p-0 cursor-pointer" id="iconNavbarSidenav">
              <div class="sidenav-toggler-inner" style="color: #ffffff;">
                <i class="sidenav-toggler-line bg-white"></i>
                <i class="sidenav-toggler-line bg-white"></i>
                <i class="sidenav-toggler-line bg-white"></i>
              </div>
            </a>
          </li>
          <li class="nav-item dropdown px-2 d-flex align-items-center" v-if="loggedIn">
            <a class="nav-link text-body p-0" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
              <i class="fa fa-user cursor-pointer" style="color: #ffffff;"></i>
            </a>
            <ul class="dropdown-menu dropdown-menu-end px-2 py-3 me-sm-n4" aria-labelledby="dropdownMenuButton">
              <li class="mb-2">
                <a class="dropdown-item border-radius-md" href="#">
                  <div class="d-flex py-1">
                    <div class="my-auto">
                      <i class="fa fa-message fa-user-tie mr-2"></i>
                    </div>
                    <router-link :to="{path:'profile'}" class="d-flex flex-column justify-content-center">
                      <h6 class="text-sm font-weight-normal mb-1">
                        <span class="font-weight-bold">Profile</span>
                      </h6>
                    </router-link>
                  </div>
                </a>
              </li>
              <li class="mb-2">
                <a class="dropdown-item border-radius-md" href="#">
                  <div class="d-flex py-1">
                    <div class="my-auto">
                      <i class="fa fa-light fa-arrow-right-from-bracket mr-2"></i>
                    </div>
                    <div @click="logOut" class="d-flex flex-column justify-content-center">
                      <h6 class="text-sm font-weight-normal mb-1">
                        <span class="font-weight-bold">Logout</span>
                      </h6>
                    </div>
                  </div>
                </a>
              </li>
            </ul>
          </li>
          <li class="nav-item dropdown px-3 d-flex align-items-center">
            <a href="#" class="nav-link text-body p-0" id="dropdownMenuButton2" data-bs-toggle="dropdown" aria-expanded="false">
              <i class="fa fa-bell cursor-pointer m-2" style="color: #ffffff;"></i><span v-if="msgcount>0" class="position-absolute top-5 start-80 translate-middle badge rounded-pill bg-warning">{{msgcount}}</span>
            </a>
            <ul class="dropdown-menu dropdown-menu-end px-2 py-3 me-sm-n4" aria-labelledby="dropdownMenuButton2">
              <li class="mb-2">
                <a class="dropdown-item border-radius-md" href="#">
                  <div class="d-flex py-1">
                    <div class="my-auto">
                      <i class="fa fa-message fa-user-tie mr-2"></i>
                    </div>
                    <router-link :to="{path:'/messages'}" class="d-flex flex-column justify-content-center">
                      <h6 class="text-sm font-weight-normal mb-1">
                        <span class="font-weight-bold">Messages <span class="badge rounded-pill bg-warning">
                          <span v-if="loading" class="spinner-border spinner-border-sm text-white"></span>
                          <span v-else>{{msgcount}}</span>
                        </span></span>
                      </h6>
                    </router-link>
                  </div>
                </a>
              </li>
              <li v-if="loading"><span class="spinner-border spinner-border-sm text-white"></span></li>
              <li v-else class="mb-2" v-for="(message,index) in messagesList" :key="index">
                <a class="dropdown-item border-radius-md">
                  <div class="d-flex py-1">
                    <div class="my-auto mr-2">
                      <i class="fa fa-regular fa-message fa-xl"></i>
                    </div>
                    <router-link :to="{path:'/messages/read/'+message.msg_to+'/'+message.id_message}" class="d-flex flex-column justify-content-center">
                      <h6 class="text-sm font-weight-normal mb-1">
                        <span class="font-weight-bold">{{message.infotxt}}</span> from {{message.msg_from}}
                      </h6>
                      <p class="text-xs text-primary mb-0 ">
                        <i class="fa fa-clock me-1" aria-hidden="true"></i>
                        {{message.minutes}}
                      </p>
                    </router-link>
                  </div>
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import DashboardService from "@/services/dashboard.service";
export default {
  name: "NavigationTop",
  props: {
    pages: String,
    subpages: String,
  },
  computed:{
    loggedIn(){
      return this.$store.state.auth.status.loggedIn;
    },
  },
  methods:{
    logOut(){
      this.$store.dispatch('auth/logout');
      this.$router.push('/login');
    },
    getMessages(){
      this.loading = true;
      DashboardService.getItemMessages(this.dataJson).then(
          (response)=>{
            if(response.data.code==1){
              this.msgcount = response.data.data.count;
              this.messagesList = response.data.data.messages;
              this.$store.state.messagesList = this.messagesList;
              this.$store.state.messageCount = this.msgcount
              this.loading = false;
            }else{
              this.loading = false;
            }
          }
      )
    },
    countDownTimer() {
      setInterval(() => {
        if (this.countDown > 0) {
          this.countDown--;
        } else {
          //clearInterval(timer);
          this.countDown = this.defaultCountdown;
          this.getMessages();
        }
      }, 1000);
    },
    toggle(){
      this.$store.state.menucollapse = !this.$store.state.menucollapse;
      if(!this.$store.state.menucollapse){
        document.body.classList.remove('g-sidenav-pinned')
        this.$store.state.menuclass = 'navbar-expand-xs';
        this.$store.state.sandwichIcon = '';
        this.$store.state.closeclass = 'd-none';
      }else{
        document.body.classList.add('g-sidenav-pinned')
        this.$store.state.closeclass = '';
        this.$store.state.menuclass = 'navbar-expand-xl';
        this.$store.state.sandwichIcon = 'g-sidenav-pinned';
      }
    }
  },
  data:()=>({
    defaultCountdown: 30,
    msgcount: 0,
    messagesList:[],
    loading: false,
    countDown: 30
  }),
  beforeMount() {
    this.getMessages();
  },
  mounted() {
    //this.countDownTimer();
  },
  watch:{
  }
}
</script>

<style scoped>

</style>