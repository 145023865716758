<template>
  <div class="col-lg-12">
    <div class="col-md-12 mb-lg-0 mb-4">
      <div class="card">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
              <ul class="list-group">
                <li v-for="(merchant, index) in partnerdata" :key="index" class="list-group-item border-0 d-flex p-4 mb-2 bg-gray-100 border-radius-lg">
                  <div class="row">
                    <MerchantInfo :merchantid="merchant.code" :merchantname="merchant.code"></MerchantInfo>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DashboardService from "@/services/dashboard.service";
import MerchantInfo from "@/views/MerchantInfo";
export default {
  name: "GenerateMBayarVA",
  components:{
    MerchantInfo
  },
  props:{
    partnerdata:undefined
  },
  methods:{
    prepareMount(){
      this.loading = true;
      DashboardService.getPartnerLink(this.dataJsonInfo).then(
          (response)=>{
            if(response.data.code==1){
              this.dataMerchant = response.data.data;
              this.loading = false;
            }else{
              this.loading = false;
            }
          }
      )
    }
  },
  computed:{
    currentRouteName() {
      return this.$route.name;
    }
  },
  beforeMount() {
    this.prepareMount()
  },
  data:()=>({
    merchantRegistrationId:'',
    dataMerchant:[{}],
    loading: true,
  })
}
</script>

<style scoped>

</style>