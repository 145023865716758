export const globvar = {
    namespaced: true,
    state: {
        menucollapse: false,
        menuclass: 'navbar-expand-xs',
        closeclass: 'd-none',
        sandwichIcon: '',
        messageCount:0,
        logingreeting: '',
        messagesList:[],
        fullname:'hahahah'
    },
    setup(){
        this.logingreeting = 'Welcome back';
    },
    mutations: {
        toggle(state, toggle) {
            state.menucollapse = toggle
        },
        setFullName(state,value){
          state.fullname = value;
        },
        setMessagesList(state, value){
            state.messagesList = value
        },
        setMessageCount(state, value){
            state.messageCount = value
        }
    },
    actions: {
        dotoggle(){
            this.state.menucollapse = !this.state.menucollapse;
            if(!this.state.menucollapse){
                document.body.classList.remove('g-sidenav-pinned')
                this.state.menuclass = 'navbar-expand-xs';
                this.state.sandwichIcon = '';
                this.state.closeclass = 'd-none';
            }else{
                document.body.classList.add('g-sidenav-pinned');
                this.state.closeclass = '';
                this.state.menuclass = 'navbar-expand-xl';
                this.state.sandwichIcon = 'g-sidenav-pinned';
            }
        }
    },
}