<template>
  <DashboardLeftMenu></DashboardLeftMenu>
  <TableClientReportLog></TableClientReportLog>
</template>

<script>
import DashboardLeftMenu from "@/components/DashboardLeftMenu";
import TableClientReportLog from "@/views/TableClientReportLog";
export default {
  name: "RouteClientReport",
  components:{
    DashboardLeftMenu,
    TableClientReportLog
  }
}
</script>

<style scoped>

</style>