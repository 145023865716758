<template>
  <main class="main-content">
    <NavigationTop :pages=currentRouteName :subpages=currentRouteName></NavigationTop>
    <div class="container-fluid py-4">
      <div class="row">
        <div class="col-12" v-if="loading">
          <img class="mx-auto d-block" :src="imageLoading" style="width: 100px; height: 80px;"/>
        </div>
        <div class="col-12" v-if="!loading">
          <p>Server Whitelist, be carefull when editing.</p>
          {{message}}
          <Vue3JsonEditor v-model="json"
                           :mode="'view'"
                           :show-btns="showBtn"
                           :expandedOnStart="true"
                           @has-error="onError"
                           @json-change="onJsonChange"
                           @json-save="onJsonSave"
          >

          </Vue3JsonEditor>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { Vue3JsonEditor } from 'vue3-json-editor'
import NavigationTop from "@/components/NavigationTop";
import DashboardService from "@/services/dashboard.service";
export default {
  name: "ServerWhitelist",
  components:{
    NavigationTop,
    Vue3JsonEditor
  },
  computed:{
    currentRouteName() {
      return this.$route.name;
    }
  },
  data:()=>({
    imageLoading: require('@/assets/img/cube_loading.gif'),
    loading: true,
    message:'',
    showBtn: true,
    json: []
  }),
  methods:{
    onError (value) {
      this.message = value;
    },
    loadWhiteList(){
      this.showBtn = false;
      DashboardService.getWebWhitelistData().then(
          (response)=>{
            if(response.data.code==1){
              this.json = response.data.data;
              this.loading = false;
              this.showBtn = true;
            }else{
              this.loading = false;
              this.showBtn = true;
            }
          }
      )
    },
    onJsonChange () {
      this.message = '';
    },
    onJsonSave (value) {
      this.showBtn = false;
      DashboardService.postWhitelistData(value).then(
          (response)=>{
            this.message = response.message;
            this.showBtn = true;
          }
      )
    }
  },
  beforeMount() {
    this.loadWhiteList();
  }
}
</script>

<style scoped>

</style>