<template>
  <main class="main-content">
    <NavigationTop :pages=currentRouteName :subpages=currentRouteName></NavigationTop>
    <div class="container-fluid py-4">
      <div class="row">
        <div class="col-md-12 mt-4">
          <div class="card">
            <div class="card-header pb-0 px-3">
              <h6 class="mb-0">Messages</h6>
            </div>
            <div v-if="loading" class="card-body pt-4 p-3">
              <img :src="imageLoading" width="100" height="100">
            </div>
            <div v-else class="card-body pt-4 p-3">
              <ul class="list-group">
                <li v-for="(msg,index) in message" :key="index" class="list-group-item border-0 d-flex p-4 mb-2 bg-gray-100 border-radius-lg">
                  <div class="d-flex flex-column">
                    <h6 class="mb-3 text-sm"><strong>From : </strong>{{msg.msg_from}}</h6>
                    <h5 class="mb-3 text-sm"><strong>Subject : </strong> {{msg.msg_subject}}</h5>
                    <span class="mb-2 text-xs">Message Date: <span class="text-dark font-weight-bold ms-sm-2">{{msg.msg_time}}</span></span>
                    <span class="mb-2 text-xs">Message: <span class="text-dark ms-sm-2 font-weight-bold">{{msg.msg_txt}}</span></span>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import DashboardService from "@/services/dashboard.service";
import NavigationTop from "@/components/NavigationTop";
export default {
  name: "MessageRead",
  components:{
    NavigationTop
  },
  props:{
    userid: String,
    messageid: String,
  },
  methods:{
    readMessage(){
      this.loading = true;
      DashboardService.readItemMessage(this.dataJson).then(
          (response)=>{
            if(response.data.code==1){
              this.message = response.data.data.messages;
              this.loading = false;
            }else{
              this.loading = false;
            }
          }
      )
    }
  },
  computed:{
    currentRouteName() {
      return this.$route.name;
    },
  },
  data:()=>({
    dataJson:{
      id: ''
    },
    imageLoading: require('@/assets/img/cube_loading.gif'),
    loading: false,
    msgindex: 0,
    message:[]
  }),
  watch:{
    messageid:{
      immediate: true,
      deep: true,
      handler(){
        this.dataJson.id = this.messageid;
        this.readMessage();
      }
    }
  },
  mounted() {
  }
}
</script>

<style scoped>

</style>