<template>
  <div class="row">
    <div class="col-12">
        <div class="card" >
          <div class="card-header bg-gray-200">Edit Merchant Data <button @click="doViewFormEditMerchant"><i :class="partnerInfoIcon"></i></button> </div>
          <div class="card-body bg-gray-100" v-if="partner_info_collapsed">
            <fieldset :disabled="loading_partner">
              <div class="mb-3">
                <label for="form_p_name" class="form-label">Merchant Name</label>
                <input type="text" class="form-control" id="form_p_name" v-model="dataJsonInfo.form_p_name" placeholder="Partner Name">
                <span v-if="v$.dataJsonInfo.form_p_name.$error">{{ v$.dataJsonInfo.form_p_name.$errors[0].$message }}</span>
              </div>
              <div class="mb-3">
                <label for="form_p_name" class="form-label">Merchant Address</label>
                <input type="text" class="form-control" id="form_p_address" v-model="dataJsonInfo.form_p_address" placeholder="Merchant Name">
                <span v-if="v$.dataJsonInfo.form_p_address.$error">{{ v$.dataJsonInfo.form_p_address.$errors[0].$message }}</span>
              </div>
              <div class="mb-3">
                <label for="form_p_phone" class="form-label">Merchant Phone</label>
                <input type="text" class="form-control" id="form_p_phone" v-model="dataJsonInfo.form_p_phone" placeholder="Merchant Name">
                <span v-if="v$.dataJsonInfo.form_p_phone.$error">{{ v$.dataJsonInfo.form_p_phone.$errors[0].$message }}</span>
              </div>
              <div class="mb-3">
                <label for="form_p_phone" class="form-label">Merchant Email</label>
                <input type="text" class="form-control" id="form_p_email" v-model="dataJsonInfo.form_p_email" placeholder="Merchant Name">
                <span v-if="v$.dataJsonInfo.form_p_email.$error">{{ v$.dataJsonInfo.form_p_email.$errors[0].$message }}</span>
              </div>
              <div class="mb-3">
                <label for="form_p_phone" class="form-label">Merchant VA</label>
                <input type="text" class="form-control" id="form_p_va" v-model="dataJsonInfo.form_p_va" placeholder="Merchant Name">
                <span v-if="v$.dataJsonInfo.form_p_va.$error">{{ v$.dataJsonInfo.form_p_va.$errors[0].$message }}</span>
              </div>
              <div class="mb-3">
                <ol class="text-xs mb-3">
                  <li>Make sure all data correct, name, email and phone number</li>
                  <li>Click Generate VA button to make request, OTP will send to mobile number</li>
                  <li>Check text message for OTP, fill box below with OTP and then click submit for completing registration process</li>
                </ol>
                <button @click="generateVA(dataJsonInfo.form_p_id)" class="btn btn-success mb-2 mr-2" :disabled="generatedisable" type="button" >Generate VA</button>
                <div v-if="showOtpBox">
                  <div class="col-md-12 mb-md-0 mt-4">
                    {{completeRegistrationData}}
                    {{messageregistration}}
                    {{registrationError}}
                    <div class="card card-body border card-plain border-radius-lg d-flex align-items-center flex-row">
                      <span class="text-bold mr-2">[{{otpprefix}}] -</span>
                      <h6 class="mb-0"><input type="text" v-model="partnerJson.otpva" class="form-control"></h6>
                      <button @click="sendOTP(dataJsonInfo.form_p_id)" class="btn bg-gradient-primary ml-2 mb-0 mr-2"><i class="fas fa-plus" aria-hidden="true"></i>Validate OTP</button>
                      <button v-if="showResend" @click="resendOTP(dataJsonInfo.form_p_id)" class="btn bg-gradient-primary ml-2 mb-0 mr-2"><i class="fas fa-plus" aria-hidden="true"></i>Resend OTP</button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group d-flex align-items-center justify-content-between">
                <span class="text-sm text-bold">Activate Merchant?</span>
                <div class="form-check form-switch ms-3">
                  <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault30" v-model="dataJsonInfo.form_p_active" >
                </div>
              </div>
              <div class="form-group d-flex align-items-center justify-content-between">
                <span class="badge bg-primary">Balance : {{dataJsonInfo.form_p_balance}}</span>
              </div>
            </fieldset>
            <span v-show="loading_partner" class="spinner-border spinner-border-sm"></span>
            <div class="card">
              <ul class="list-group list-group-flush" >
                <li class="list-group-item" v-for="(data,index) in balanceData" :key=index><span class="badge bg-success">{{index}}</span> : {{data}}</li>
              </ul>
            </div>
            {{registrationData}}
            {{registerMessage}}
            <div class="mb-3">
              <span v-show="loading_partner" class="spinner-border spinner-border-sm"></span>
              <button v-show="dataJsonInfo.form_p_gid" @click="doMerchantGetBalance(dataJsonInfo.form_p_id)" class="btn btn-primary text-white mr-2" :disabled="loading_partner" type="button" >Get Balance</button>
              <button v-show="!dataJsonInfo.form_p_va" @click="doMerchantAdd" class="btn btn-success mr-2" :disabled="loading_partner" type="button" >Add</button>
              <button v-show="dataJsonInfo.form_p_id" @click="doMerchantEdit" class="btn btn-warning mr-2" :disabled="loading_partner" type="button" >Edit</button>
              <button @click="doClearInfo" class="btn btn-info" :disabled="loading_partner" type="button" >Clear</button>
            </div>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import DashboardService from "@/services/dashboard.service";
import useValidate from '@vuelidate/core'
import {email, required, minLength,maxLength} from '@vuelidate/validators'
export default {
  name: "PageMerchantInfo",
  props:{
    merchant: undefined,
    collapsed: Boolean,
  },
  computed:{
    generatedisable(){
      if(this.dataJsonInfo.form_p_id && !this.dataJsonInfo.form_p_va){
        return false;
      }else{
        return true;
      }
    }
  },
  methods:{
    doViewFormEditMerchant() {
      this.partner_info_collapsed = !this.partner_info_collapsed;
      if(this.partner_info_collapsed){
        this.partnerInfoIcon = 'fa-solid fa-chevron-up';
      }else{
        this.partnerInfoIcon = 'fa-solid fa-chevron-down';
      }
    },
    doClearInfo(){
      this.dataJsonInfo.form_p_id='';
      this.dataJsonInfo.form_p_name='';
      this.dataJsonInfo.form_p_address='';
      this.dataJsonInfo.form_p_email='';
      this.dataJsonInfo.form_p_phone='';
      this.dataJsonInfo.form_p_va='';
      this.dataJsonInfo.form_p_active='';
      this.dataJsonInfo.form_p_balance="";
      this.dataJsonInfo.item=[];
      this.balanceData=[];
    },
    doMerchantAdd(){
      this.loading_partner = true;
      DashboardService.registerMerchant(this.dataJsonInfo).then(
          (response)=>{
            if(response.data.code==1){
              this.registerMessage = response.data.message;
              this.registerError = response.data.error;
              this.loading_partner = false;
            }else{
              this.registerMessage = response.data.message;
              this.registerError = response.data.error;
              this.loading_partner = false;
            }
          }
      )
    },
    doMerchantEdit(){
      this.loading_partner = true;
      DashboardService.registerMerchant(this.dataJsonInfo).then(
          (response)=>{
            console.log(response);
            if(response.data.code==1){
              this.registerMessage = response.data.message;
              this.registerError = response.data.error;
              this.loading_partner = false;
            }else{
              this.registerMessage = response.data.message;
              this.registerError = response.data.error;
              this.loading_partner = false;
            }
          }
      )
    },
    resendOTP(id){
      this.loadingregister = true;
      this.showResend = false;
      const data = {
        method:'resendOTP',
        partnerid:id,
        otp: this.partnerJson.otpva
      }
      DashboardService.generateVA(data).then(
          (response)=>{
            if(response.data.code==1){
              this.registrationData = response.data.data;
              this.registrationError = response.data.error;
              this.otpprefix = this.registrationData.tokenPrefix;
              this.showOtpBox = true;
              this.loadingregister = false;
              this.disablevagenerate = true;
            }else{
              this.registrationError = response.data.error;
              this.loadingregister = false;
            }
          }
      )
    },
    sendOTP(id){
      this.loadingregister = true;
      const data = {
        method:'validateOTP',
        partnerid:id,
        regdata:{
          tokenPrefix: this.otpprefix
        },
        otp: this.partnerJson.otpva
      }
      DashboardService.generateVA(data).then(
          (response)=>{
            if(response.data.code==1){
              this.registrationData = response.data.data;
              this.registrationError = response.data.error;
              this.otpprefix = this.registrationData.tokenPrefix;
              this.showOtpBox = true;
              this.loadingregister = false;
              this.disablevagenerate = true;
            }else{
              this.registrationError = response.data.error;
              this.loadingregister = false;
            }
          }
      )
    },
    generateVA(id){
      this.loadingregister = true;
      const data = {
        method:'registerMerchant',
        partnerid:id
      }
      DashboardService.generateVA(data).then(
          (response)=>{
            if(response.data.code==1){
              this.registrationData = response.data.data;
              this.registrationError = response.data.error;
              this.otpprefix = this.registrationData.tokenPrefix;
              this.showResend = true;
              this.showOtpBox = true;
              this.loadingregister = false;
              this.disablevagenerate = true;
            }else{
              this.registrationError = response.data.error;
              this.loadingregister = false;
            }
          }
      )
    },
    countDownTimer() {
      const timer = setInterval(() => {
        if (this.countDown > 0) {
          this.countDown--;
        } else {
          if(this.resendCounter<4){
            this.showResend = true;
            this.resendCounter++;
          }
          clearInterval(timer);
        }
      }, 1000);
    },
    doMerchantGetBalance(id){
      this.loading_partner = true;
      const data = {
        method:'getMerchantBalance',
        partnerid:id
      }
      DashboardService.generateVA(data).then(
          (response)=>{
            if(response.data.code==1){
              this.balanceData = response.data.data;
              this.balanceError = response.data.error;
              this.loading_partner = false;
            }else{
              this.balanceError = response.data.error;
              this.loading_partner = false;
            }
          }
      )
    }
  },
  data:()=>({
    v$: useValidate(),
    vadisabled: false,
    loading_partner: false,
    showOtpBox: false,
    resendCounter : 0,
    partnerInfoIcon: 'fa-solid fa-chevron-down',
    balanceError:'',
    registerMessage:'',
    registerError:'',
    registrationError:'',
    completeRegistrationData:'',
    messageregistration:'',
    partner_info_collapsed: false,
    otpprefix: '',
    showResend: false,
    countDown: 30,
    defaultCountdown: 30,
    balanceData:[],
    dataJsonInfo: {
      form_p_id: '',
      form_p_name: '',
      form_p_address: '',
      form_p_email: '',
      form_p_phone: '',
      form_p_va: '',
      form_p_gid: '',
      form_p_active:false,
      form_p_balance: 0,
      item:''
    },
    partnerJson:{
      partnerId:'',
      otpva:'',
    }
  }),
  validations() {
    return {
      dataJsonInfo: {
        form_p_name: {required,minLength: minLength(5)},
        form_p_address: {required,maxLength:maxLength(150)},
        form_p_email: {required, email},
        form_p_phone: {required},
        form_p_va: {},
        form_p_active: {}
      },
    }
  },
  watch:{
    partner_info_collapsed:{
      immediate: true,
      deep: true,
      handler(){
        if(this.partner_info_collapsed){
          this.partnerInfoIcon = 'fa-solid fa-chevron-up';
        }else{
          this.partnerInfoIcon = 'fa-solid fa-chevron-down';
        }
      }
    },
    collapsed:{
      immediate: true,
      deep: true,
      handler(){
        this.partner_info_collapsed = this.collapsed;
      }
    },
    merchant:{
      immediate: true,
      deep: true,
      handler(){
        this.dataJsonInfo.item = this.merchant;
        this.dataJsonInfo.form_p_id = this.merchant.id;
        this.dataJsonInfo.form_p_name = this.merchant.merchant_name;
        this.dataJsonInfo.form_p_address = this.merchant.merchant_address;
        this.dataJsonInfo.form_p_email = this.merchant.merchant_email;
        this.dataJsonInfo.form_p_phone = this.merchant.merchant_phone;
        this.dataJsonInfo.form_p_va = this.merchant.merchant_va;
        this.dataJsonInfo.form_p_gid = this.merchant.merchant_groupid;
        this.dataJsonInfo.form_p_balance = this.merchant.merchant_balance;
        this.dataJsonInfo.form_p_active = this.merchant.is_active==1?true:false;
      }
    }
  }
}
</script>

<style scoped>

</style>