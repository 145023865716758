<template>
  <DashboardLeftMenu></DashboardLeftMenu>
  <TablePartners v-if="!edit"></TablePartners>
  <ClientEditPage v-if="edit" :id="$route.params.id" :code="$route.params.code"></ClientEditPage>
</template>

<script>
import DashboardLeftMenu from "@/components/DashboardLeftMenu";
import TablePartners from "@/views/TablePartners";
import ClientEditPage from "@/views/ClientEditPage";
export default {
  name: "RouterClientCrud",
  props:{
    edit: Boolean
  },
  components:{
    DashboardLeftMenu,
    TablePartners,
    ClientEditPage
  }

}
</script>

<style scoped>

</style>