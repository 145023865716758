import { createStore} from "vuex";
import {auth} from "@/store/auth.module";
import {globvar} from "@/store/state.module";

const store = createStore({
    modules:{
        auth,
        globvar
    }
})

export default store;

