<template>
  <DashboardLeftMenu></DashboardLeftMenu>
  <MessageRead :userid="$route.params.userid" :messageid="$route.params.messageid"></MessageRead>
</template>

<script>
import DashboardLeftMenu from "@/components/DashboardLeftMenu";
import MessageRead from "@/views/MessageRead";
export default {
  name: "RouteMessageRead",
  components:{
    DashboardLeftMenu,
    MessageRead
  }
}
</script>

<style scoped>

</style>