<template>
  <main class="main-content">
    <NavigationTop :pages=currentRouteName :subpages=currentRouteName></NavigationTop>
    <div class="container-fluid py-4">
      <PageMerchantInfo :collapsed="editMerchant" :merchant="merchantItemData" v-if="editMerchant"></PageMerchantInfo>
      <div class="row">
        <div class="col-12">
          <div class="card mb-12">
            <div class="card-header pb-0">
              <h6>Data {{currentRouteName}}</h6>
            </div>
            <div class="card">
              <div class="card-body p-3">
                <TableFilter :f_submit="searchSubmit" :f_loading=loading :f_name="true" :f_user_email="true" :f_user_phone="true"></TableFilter>
              </div>
              <div class="card-body p-3">
                <EasyDataTable
                    show-index
                    buttons-pagination
                    alternating
                    v-model:server-options="serverOptions"
                    :server-items-length="numberOfPages"
                    :loading="loading"
                    :headers="headers"
                    :items="transactions"
                    :search-field="searchField"
                    :search-value="searchValue"
                >
                  <template #item-is_active="{is_active}">
                    <div class="bg-success text-center" v-if="is_active==1">
                      <span class="badge badge-success">Active</span>
                    </div>
                    <div class="bg-gray-600 text-center" v-else>
                      <span class="badge badge-warning">Not Active</span>
                    </div>
                  </template>
                  <template #item-id="item">
                    <div class="bg-success text-center">
                      <button @click="viewMerchant(item)"  class="btn-sm btn-success">View</button>
                    </div>
                  </template>
                  <template #loading>
                    <img :src="imageLoading" style="width: 100px; height: 80px;"/>
                  </template>
                </EasyDataTable>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import DashboardService from "@/services/dashboard.service";
import NavigationTop from "@/components/NavigationTop";
import TableFilter from "@/views/TableFilter";
import PageMerchantInfo from "@/views/PageMerchantInfo";
export default {
  name: "TableMerchantList",
  components:{
    NavigationTop,
    TableFilter,
    PageMerchantInfo
  },
  computed:{
    currentRouteName() {
      return this.$route.name;
    }
  },
  methods: {
    searchSubmit(dataParam) {
      this.searchParam = dataParam;
      this.searchParam.trxType = 'partners';
      this.searchParam.serverOptions = this.serverOptions;
      this.fetchData();
    },
    viewMerchant(id){
      this.editMerchant = true;
      this.merchantItemData = id;
    },
    fetchData(){
      this.loading = true;
      this.searchParam.trxType = 'partners';
      this.searchParam.serverOptions = this.serverOptions;
      DashboardService.getMerchantList(this.searchParam).then(
          (response)=>{
            this.transactions = response.data.data.data;
            this.numberOfPages = response.data.data.dataCount;
            this.loading = false;
          }
      );
    }
  },
  data:()=>({
    editMerchant: false,
    merchantItemData: [],
    imageLoading: require('@/assets/img/cube_loading.gif'),
    datapartner: undefined,
    transactions: [],
    content:"",
    partnerEdit: false,
    itemEdit: undefined,
    infocolapsed: Boolean,
    addPartnerText : 'Add New Merchant',
    numberOfPages : 0,
    searchField:undefined,
    searchValue:'',
    partnerAdd: false,
    loading: false,
    headers: [
      { text: "Merchant Name", value: "merchant_name" , sortable: true},
      { text: "Merchant Phone", value: "merchant_phone" , sortable: true},
      { text: "Email", value: "merchant_email" },
      { text: "Phone", value: "merchant_phone" },
      { text: "Address", value: "merchant_address" },
      { text: "VA Number", value: "merchant_va" , sortable: true},
      { text: "Balance", value: "merchant_balance" , sortable: true},
      { text: "Status", value: "is_active" , sortable: true},
      { text: "View", value: "id" , sortable: true},
    ],
    searchParam:{},
    serverOptions:{
      page: 1,
      rowsPerPage: 25,
      sortBy:'',
      sortType:'',
    }
  }),
  mounted() {
    this.fetchData();
  },
  beforeUnmount() {
  },
  watch:{
    serverOptions:{
      handler(){
        this.searchParam.serverOptions = this.serverOptions;
        this.fetchData(this.searchParam);
      },
      deep: true
    },
  }
}
</script>

<style scoped>

</style>