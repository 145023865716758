<template>
     <main class="main-content">
        <div class="container">
            <div class="alert alert-danger" role="alert" v-if="isNotFound">
                Transaction not found
            </div>
            <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status" v-if="loading">
                <span class="visually-hidden">Loading...</span>
            </div>
            <div class="center" v-else>
                <div class="card mt-4">
                    <div class="row"> 
                        <div class="col-12"> 
                            <div class="card-body">
                                <div class="card border border-black mb-3" >
                                    <div class="card-body">
                                        <form>
                                            <div class="row">
                                                <div class="col-12">
                                                    <h6 class="">Payment Detail</h6>
                                                </div>
                                            </div>
                                            <div class="row mt-2">
                                                <div class="col-12">
                                                    <div class="mb-3 row">
                                                        <label for="staticEmail" class="col-4 col-form-label">Total Payment</label>
                                                        <div class="col-7">
                                                            <input type="text" readonly class="form-control-plaintext" :value="formattingNumber(form.amount)">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-12">
                                                    <div class="mb-3 row">
                                                        <label for="staticEmail" class="col-4 col-form-label">Invoice No.</label>
                                                        <div class="col-7">
                                                            <input type="text" readonly class="form-control-plaintext" :value="form.invoiceNo">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-12">
                                                    <div class="mb-3 row">
                                                        <label for="staticEmail" class="col-4 col-form-label">Payment Reference No.</label>
                                                        <div class="col-7">
                                                            <input type="text" readonly class="form-control-plaintext" :value="form.paymentRefNo">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row"> 
                        <div class="col-6">
                            <img src="/assets/img/qris.png" class="img-fluid float-start ml-2" style="width: 100px; height: 100px;">
                        </div>
                        <div class="col-6">
                            <img src="/assets/img/mbayar.jpg" class="img-fluid float-end" style="width: 300px;">
                        </div>
                    </div>
                    <div class="row"> 
                        <div class="col-12 text-center">
                            <h4>Remaining Time</h4>
                        </div>
                    </div>
                    <div class="row mb-4"> 
                        <div class="col-12 text-center">
                            <h1><strong>{{ getRemainingTime }}</strong></h1>
                        </div>
                    </div>
                    <div class="row mb-4"> 
                        <div class="col-12">
                            <qrcode-vue :value="qrString" :size="qrSize" level="H" class="qrvalue-style" />
                        </div>
                    </div>
                    <div class="row mb-4"> 
                        <div class="col-12 text-center">
                            <button type="button" class="btn btn-outline-primary" @click="goToMerchant">Back to merchant</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
  </template>
  
  <script>
import RequestService from "@/services/request.service";
import e2payApis from "@/services/e2pay-apis";
import {formatNumber} from "@/services/number-format"
import QrcodeVue from 'qrcode.vue'
  
  export default {
    name: "PaymentPage",
    mounted() {
        console.log('mounted');
        if (this.$route.query.transId == undefined) {
            this.$router.go(-1);
        } else {
            this.fetchData();
            this.repeater = setInterval(() => {
                this.fetchData();
                if (this.remainingTime <= 0) {
                    this.$router.push("/payment-failed?status=2&redirectUrl=" + this.responseUrl);
                } else if(this.status == 1 ) {
                    this.$router.push("/payment-success?isSuccess=1&redirectUrl=" + this.responseUrl);
                } else if(this.status > 1 ) {
                    this.$router.push("/payment-success?isSuccess=0&redirectUrl=" + this.responseUrl);
                }
                
            }, 10000)
        }
    },
    beforeUnmount() {
        clearInterval(this.repeater);
    },
    components:{
        QrcodeVue
    },
    computed: {
        getRemainingTime: function(){
            let time = this.remainingTime / 60
            let minutes = parseInt(time);
            let secondes = Math.round((time - minutes) * 60);
            if (minutes < 10) {
                minutes = "0"+minutes
            }
            if (secondes < 10) {
                secondes = "0"+secondes
            }
            return minutes+":"+secondes;
        },
    },
    methods:{
        formattingNumber(val) {
            return "Rp." + formatNumber(val, 0) + ",-";
        },
        fetchData(){
            this.loading = true;
            RequestService.postRequest(e2payApis.GET_QR_DETAIL + this.$route.query.transId, this.searchParam, false).then(
                (response)=>{
                    let resp = response.data.data;
                    if (resp && resp.length > 0) {
                        this.form.invoiceNo = resp[0].invoice_no;
                        this.form.paymentRefNo = resp[0].payment_ref_no;
                        this.form.amount = resp[0].amount;
                        this.qrString = resp[0].qr_string;
                        let dateExp = new Date(resp[0].qr_expiry_timestamp);
                        let dateNow = new Date(resp[0].serverTime.date);
                        this.isExpired = dateNow > dateExp;
                        var diff = Math.abs(dateExp - dateNow);
                        this.remainingTime = diff/1000;
                        this.responseUrl = resp[0].response_url;
                        this.status = resp[0].paid_status;
                    } else {
                        this.isNotFound = true;
                    }
                    this.loading = false;
                    if (this.status == 1) {
                        this.$router.push("/payment-success?isSuccess=1&status=2&redirectUrl=" + this.responseUrl);
                    } else if (this.isExpired) {
                        this.$router.push("/payment-failed?status=2&redirectUrl=" + this.responseUrl);
                    } else if (this.remainingTime <= 0) {
                        this.$router.push("/payment-success?isSuccess=0&status=1&redirectUrl=" + this.responseUrl);
                    } else {
                        this.start();
                    }
                }
            );
        },
        start () {
            if (!this.timer) {
                this.timer = setInterval( () => {
                    if (this.remainingTime > 0) {
                        this.remainingTime--
                    } else {
                        console.log('expired');
                        clearInterval(this.timer);
                        this.$router.push("/payment-failed?status=2&redirectUrl=" + this.responseUrl);
                    }
                }, 1000 )
            }
        },
        goToMerchant() {
            this.$router.push("/payment-redirect?redirectUrl=" + this.responseUrl);
        }
    },
    data:()=>({
        loading: false,
        isNotFound: false,
        isExpired: false,
        repeater: null,
        status: false,
        timer: null,
        qrString: "",
        qrSize: 300,
        remainingTime: 0,
        responseUrl: "",
        form: {
            invoiceNo: "",
            paymentRefNo: "",
            amount: 0,
        }
  }),
  }
  </script>
  
  <style scoped>
    .qrvalue-style {
        margin-right: auto;
        margin-left: auto;
    }
  </style>